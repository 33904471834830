import type { GameLastWinner, EventData } from "@/types";

const playerActivitySet = new Set(["win", "bigwin", "jackpot", "daily-wheel-accrual"]);
const MAX_SAVED_DATA_SIZE = 15;

const useRecentGamesWinners = () => {
	const recentWinners = useState<Map<number, GameLastWinner>>("recentWinners", () => new Map());
	const playersActivity = useState<Array<GameLastWinner>>("playersActivity", () => []);

	const { add, remove } = useSockets();
	const { data: fetchedPlayersActivity } = useAsyncFetch({
		path: "/rest/players-activity/",
		method: "get",
		options: {
			cached: true,
			default: () => [],
			transform: (data) => data.data ?? []
		},
		fetchOptions: {
			query: {
				limit: 6
			},
			onResponse: ({ response }) => {
				playersActivity.value = response._data.data ?? [];
			}
		}
	});

	add("addPlayerLastAction", ({ data }: EventData<GameLastWinner>) => {
		if (data.type === "win" || data.type === "bigwin") {
			if (recentWinners.value.size > MAX_SAVED_DATA_SIZE) {
				recentWinners.value.clear();
			}
			recentWinners.value.set(data.data.id, data);
		}

		if (playerActivitySet.has(data.type)) {
			playersActivity.value = [data, ...playersActivity.value].slice(0, MAX_SAVED_DATA_SIZE);
		}
	});

	onUnmounted(() => {
		remove("addPlayerLastAction");
	});

	return { recentWinners, fetchedPlayersActivity, playersActivity };
};

export default useRecentGamesWinners;
